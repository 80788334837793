import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileNavDrawer from "../../components/header/MobileNavDrawer";
import HeaderTop from "../../components/header/HeaderTop";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { Link } from "react-router-dom";
import urls from "../../urls";
import { colors } from "../../utils/theme";
import { useSelector } from "react-redux";
import _ from "lodash";
import discountBannerData from "../../data/banner/banner-discount.json";
import DiscountBanner from "../../components/banner/DiscountBanner";
import MobileAccountDrawer from "../../components/header/MobileAccountDrawer";

const Header = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
  discountBannerActive,
}) => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const cartData = useSelector((state) => state.cartData);
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [accountDrawerOpen, setAccountDrawerOpen] = useState(false);
  let cartItemsAmount = _.sumBy(cartData, "quantity");

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const closeMenu = () => {
    setNavMenuOpen(false);
  };

  return (
    <header style={{ zIndex: 99, position: "relative" }}>
      <DesktopHeader
        className={`header-area clearfix ${
          headerBgClass ? headerBgClass : ""
        } ${headerPositionClass ? headerPositionClass : ""}`}
      >
        <div
          className={`${headerPaddingClass ? headerPaddingClass : ""} ${
            top === "visible" ? "d-none d-lg-block" : "d-none"
          } header-top-area ${
            borderStyle === "fluid-border" ? "border-none" : ""
          }`}
        >
          <HeaderTop borderStyle={borderStyle} />
          {discountBannerActive && <DiscountBanner data={discountBannerData} />}
        </div>

        <div
          className={` ${
            headerPaddingClass ? headerPaddingClass : ""
          } sticky-bar header-res-padding clearfix bg-shadow ${
            scroll > headerTop ? "stick" : ""
          }`}
        >
          <div className={layout === "container-fluid" ? layout : "container"}>
            <div className="row">
              <div className="col-xl-2 col-lg-2 col-md-6 col-4 align-self-center">
                <Logo
                  imageUrl="/assets/img/logo/logo-liggend.webp"
                  logoClass="logo"
                />
              </div>
              <div className="col-xl-7 col-lg-7 d-none d-lg-block">
                <NavMenu />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-8 align-self-center">
                <IconGroup />
              </div>
            </div>
          </div>
        </div>
      </DesktopHeader>
      <MobileHeader>
        <div
          className={"sticky-bar stick header-res-padding clearfix bg-shadow"}
        >
          <MobileItemsWrapper>
            <div className={"items-left-container"}>
              <HamburgerMenu
                onClick={() => {
                  setNavMenuOpen(!navMenuOpen);
                  setAccountDrawerOpen(false);
                }}
                className={navMenuOpen ? "active" : ""}
              >
                <span />
                <span />
                <span />
              </HamburgerMenu>
              {/* account icon */}
              {!auth.isEmpty && (
                <AccountButton
                  onClick={() => {
                    closeMenu();
                    setAccountDrawerOpen(!accountDrawerOpen);
                  }}
                >
                  <AccountIcon />
                </AccountButton>
              )}
            </div>
            {/* header logo */}
            <Logo
              imageUrl="/assets/img/logo/logo-liggend.webp"
              logoClass="logo"
              closeMenu={closeMenu}
            />
            <CartLink className="icon-cart" to={urls.cart}>
              <CartIcon />
              <Counter>
                <span>{cartItemsAmount}</span>
              </Counter>
            </CartLink>
          </MobileItemsWrapper>
          {/* mobile menu */}
          <MobileNavDrawer
            active={navMenuOpen}
            closeMenu={closeMenu}
            openAccountDrawer={() => setAccountDrawerOpen(true)}
          />
          {!auth.isEmpty && <MobileAccountDrawer active={accountDrawerOpen} />}
        </div>
        {discountBannerActive && (
          <DiscountBanner data={discountBannerData} mobileOnly />
        )}
      </MobileHeader>
    </header>
  );
};

const HamburgerMenu = styled.div`
  display: block;
  position: relative;
  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
  align-self: end;
  padding-bottom: 4px;

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;

    background: ${colors.hamburger};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition:
      transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -3px);
      background: ${colors.hamburger};
    }

    span:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    span:nth-last-child(1) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
`;

const AccountButton = styled.button`
  background: none;
  border: none;
`;

const AccountIcon = styled.div`
  width: 28px;
  height: 28px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/account.svg") no-repeat center;
  mask: url("/assets/img/button-icons/account.svg") no-repeat center / contain;
  transition: background-color 0.2s ease;
`;

const MobileItemsWrapper = styled.div`
  display: grid;
  padding-left: 1rem;
  padding-right: 1rem;
  grid-template-columns: 1fr 125px 1fr;

  .items-left-container {
    display: flex;
    gap: 6px;
  }
`;

const CartLink = styled(Link)`
  width: fit-content;
  font-size: 36px;
  position: relative;
  display: inline-block;
  border: none;
  background: transparent;
  align-self: end;
  justify-self: end;
  padding-bottom: 4px;
`;

const Counter = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  top: -12px;
  right: -9px;
  width: 20px;
  height: 20px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.primary};

  span {
    align-self: center;
  }
`;

const CartIcon = styled.div`
  width: 25px;
  height: 25px;
  background-color: #333;
  -webkit-mask: url("/assets/img/button-icons/cart.svg") no-repeat center;
  mask: url("/assets/img/button-icons/cart.svg") no-repeat center / contain;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.primary};
  }

  @media ${device.lg} {
    width: 32px;
    height: 32px;
  }
`;

const MobileHeader = styled.div`
  display: block;
  @media ${device.lg} {
    display: none;
  }
`;

const DesktopHeader = styled.div`
  display: none;
  @media ${device.lg} {
    display: block;
  }
`;

Header.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default Header;
