import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { load, save } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./translations/i18n";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import firebase from "./services/firebase";
import { actionTypes, createFirestoreInstance } from "redux-firestore";
import {
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
} from "./constants/FirebaseConstants";
import { colors } from "./utils/theme";
import nlNL from "antd/lib/locale/nl_NL";
import { ConfigProvider } from "antd";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  onAuthStateChanged: (authData, firebase, dispatch) => {
    // Clear orders from redux state on user logout, but preserve products
    if (!authData) {
      dispatch({
        type: actionTypes.CLEAR_DATA,
        preserve: {
          data: [FIRESTORE_PRODUCTS_TABLE, FIRESTORE_FEATURED_PRODUCTS_TABLE],
          ordered: [
            FIRESTORE_PRODUCTS_TABLE,
            FIRESTORE_FEATURED_PRODUCTS_TABLE,
          ],
        },
      });
    }
  },
};

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save())),
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colors.primary,
            fontFamily: "",
          },
          components: {
            Button: {
              colorPrimary: colors.primary,
              colorBorder: colors.primary,
              borderColorDisabled: "#d9d9d9",
              colorTextLightSolid: "#fff",
              colorText: colors.primary,
              primaryShadow: "none",
            },
            Tree: {
              nodeSelectedBg: colors.primary30,
            },
            TreeSelect: {
              nodeSelectedBg: colors.primary30,
            },
            Select: {
              optionSelectedBg: colors.primary30,
            },
            Breadcrumb: {
              linkHoverColor: colors.primary,
            },
          },
        }}
        locale={nlNL}
      >
        <App />
      </ConfigProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
