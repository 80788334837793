import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { WEBSITE_URL } from "../../constants/CompanyConstants";
import { colors } from "../../utils/theme";

export default function BlogMarkdown({ children }) {
  return (
    <StyledMarkdown
      components={{
        a: ({ node, ...props }) =>
          props.href.includes(WEBSITE_URL) ? (
            <Link to={props.href.replace(WEBSITE_URL, "")}>
              {props.children}
            </Link>
          ) : (
            <a href={props.href} target="_blank" rel={"noreferrer noopener"}>
              {props.children}
            </a>
          ),
        img: ({ node, ...props }) => (
          <figure>
            <img src={props.src} alt={props.alt} />
            {props.title && <figcaption>{props.title}</figcaption>}
          </figure>
        ),
      }}
      children={children}
    />
  );
}

const StyledMarkdown = styled(ReactMarkdown)`
  h1 {
    display: none;
  }

  p,
  a,
  li {
    font-size: 16px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.primaryDark};
    font-weight: 500;
    margin-top: 1.2em;
  }

  a {
    color: ${colors.primary};
    font-weight: 500;
    transition: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    width: 100%;
    margin: 2rem auto;

    p {
      color: ${colors.primary};
      font-style: italic;
      font-weight: 600;
      font-size: 24px;
      margin-inline: auto;
      width: fit-content;
    }
  }

  ol,
  ul {
    padding-left: 1rem;

    li {
      font-size: 16px;
      line-height: 1.75;
      font-weight: 400;
      color: ${colors.text};
    }
  }

  ul {
    list-style: circle;
  }

  figure {
    max-width: 600px;
    margin-block: 2rem;

    img {
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 2px;
    }

    figcaption {
      font-size: 14px;
      color: ${colors.textLight};
      font-weight: 400;
      font-style: italic;
    }
  }
`;
