import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import { useSelector } from "react-redux";
import { formatCategoryName } from "../../helpers/formatters";
import styled from "styled-components";
import { colors } from "../../utils/theme";

const NavMenu = () => {
  const { t, i18n } = useTranslation();
  const categories = useSelector(
    (state) => state.firestoreReducer.ordered.categories,
  );
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  const handleSetActiveSubCategory = (id) => {
    setActiveSubCategory(id);
  };

  return (
    <MainMenu>
      <nav>
        <ul style={{ textWrap: "nowrap" }}>
          <li>
            <NavLink to={urls.home} activeClassName="active" exact>
              {t("home")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={urls.shop}
              className={"has-submenu arrow-down"}
              activeClassName="active"
              exact
            >
              {t("products")}
            </NavLink>
            <ul
              className={"submenu"}
              onMouseLeave={() => setActiveSubCategory(null)}
            >
              <li onMouseEnter={() => setActiveSubCategory(null)}>
                <NavLink to={urls.shop} exact>
                  {t("all_products")}
                </NavLink>
              </li>
              {!!categories &&
                categories.map((category) => (
                  <li
                    key={category.id}
                    onMouseEnter={() => handleSetActiveSubCategory(category.id)}
                  >
                    <NavLink
                      to={urls.categorySlug + category.slug}
                      exact
                      className={
                        category.subcategories.length > 0
                          ? "has-submenu arrow-right"
                          : ""
                      }
                    >
                      {formatCategoryName(category, i18n.language)}
                    </NavLink>
                    {category.id === activeSubCategory &&
                      category.subcategories.length > 0 && (
                        <ul className={"subcategories"}>
                          {category.subcategories.map((item) => (
                            <li key={item.subcategoryId}>
                              <NavLink
                                to={
                                  urls.categorySlug +
                                  category.slug +
                                  "/" +
                                  item.slug
                                }
                                exact
                              >
                                {formatCategoryName(item, i18n.language)}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
            </ul>
          </li>
          <li>
            <NavLink to={urls.winkel} activeClassName="active" exact>
              {t("store")}
            </NavLink>
          </li>
          <li>
            <NavLink to={urls.recipes} activeClassName="active" exact>
              {t("recipes")}
            </NavLink>
          </li>
          <li>
            <NavLink to={urls.cadeaubon} activeClassName="active">
              {t("gift_card")}
            </NavLink>
          </li>
          {/*<li>*/}
          {/*  <NavLink to={urls.about} activeClassName="active">*/}
          {/*    {t("about_us")}*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <NavLink to={urls.blog} activeClassName="active" exact>*/}
          {/*    {t("blog")}*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <NavLink to={urls.delivery_returns} activeClassName="active">*/}
          {/*    {t("shipping")}*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li>
            <NavLink to={urls.contact} activeClassName="active">
              {t("contact")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </MainMenu>
  );
};

const MainMenu = styled.div`
  --min-width: 210px;

  nav {
    ul {
      display: block;
      text-align: center;

      li {
        position: relative;
        display: inline-block;
        padding: 0 15px;

        ul.submenu {
          position: absolute;
          z-index: 999;
          top: 100%;
          left: 0;

          display: none;

          width: max-content;
          min-width: var(--min-width);
          background: #fff;
          box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);

          li {
            display: block;
            margin-left: 0;
            padding: 0;

            a {
              font-size: 14px;
              font-weight: 400;
              line-height: 32px;
              border-radius: 0;
              transition: none;

              display: block;

              padding: 8px 16px;

              text-align: left;

              &.active {
                color: ${colors.primary};
              }

              &.has-submenu {
                margin-right: 0;

                &::after {
                  transition: none;
                }
              }

              &:hover {
                color: #fff;
                background: ${colors.primary};
                font-weight: 500;

                &.has-submenu::after {
                  border-bottom: 2px solid #fff;
                  border-right: 2px solid #fff;
                }
              }
            }
          }
        }

        ul.subcategories {
          position: absolute;
          top: 0;
          right: 0;
          height: fit-content;
          min-width: var(--min-width);
          transform: translate(100%);
          background: #fff;
          box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
        }

        &:hover > ul.submenu {
          display: block;
        }

        @media #{$lg-layout} {
          padding: 0 7px;
        }
        @media #{$xl-layout} {
          padding: 0 10px;
        }

        > a,
        span {
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          line-height: 94px;

          display: inline-block;

          letter-spacing: 1px;

          color: ${colors.heading};

          i {
            font-size: 15px;
            position: relative;
            top: 0;
            margin-left: 1px;
          }

          &:hover,
          &.active {
            color: ${colors.primary};
          }

          &:hover {
            &.has-submenu::after {
              border-bottom: 2px solid ${colors.primary};
              border-right: 2px solid ${colors.primary};
            }
          }

          &.has-submenu {
            --stroke-size: 8px;

            margin-right: var(--stroke-size);

            &::after {
              content: "";
              position: absolute;
              margin-left: 5px;
              width: var(--stroke-size);
              height: var(--stroke-size);
              border-bottom: 2px solid ${colors.heading};
              border-right: 2px solid ${colors.heading};
              transition: all 0.3s ease 0s;
            }

            &.arrow-down::after {
              transform: rotate(45deg);
              top: calc(50% - var(--stroke-size) / 1.25);
            }

            &.arrow-right::after {
              --stroke-size: 7px;
              transform: rotate(-45deg);
              top: calc(50% - var(--stroke-size) / 2);
            }
          }
        }
      }
    }
  }
`;

export default NavMenu;
