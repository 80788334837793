import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import Header from "../wrappers/header/Header";
import Footer from "../wrappers/footer/Footer";
import Main from "../components/core/Main";
import discountBannerData from "../data/banner/banner-discount.json";
import dayjs from "dayjs";

const Layout = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
}) => {
  const discountBannerActive = useMemo(() => {
    if (!discountBannerData) return false;
    return dayjs(
      discountBannerData["nl"]?.valid_until_date,
      "DD-MM-YYYY",
    ).isSameOrAfter(dayjs(), "day");
  }, [discountBannerData]);

  return (
    <Fragment>
      <Header
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
        discountBannerActive={discountBannerActive}
      />
      <Main discountBannerActive={discountBannerActive}>{children}</Main>
      <Footer />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
  hasPattern: PropTypes.bool,
};

export default Layout;
