import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie-player";
import * as cow from "../../assets/eating-cow.json";

const ErrorBoundaryFallback = () => (
  <Wrapper>
    <div
      style={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={cow.default}
        play
        style={{ width: 200 }}
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      />
      <h1>Ho! Er is iets aangebrand...</h1>
      <p>
        Dat is niet de bedoeling. Net zoals we nooit vlees laten aanbranden,
        zorgen we er ook voor dat onze website snel weer lekker werkt. Onze
        ontwikkelaars hebben hier bericht van gekregen en zijn al bezig om het
        probleem te fixen.
      </p>
      <h3 style={{ marginTop: 12 }}>Wat kun jij doen?</h3>
      <ul>
        <li>
          Probeer de pagina even te verversen. Soms is dat al genoeg om het weer
          sappig te maken.
        </li>
        <li>
          Lukt dat niet? Probeer het later nog eens; wij zijn druk bezig om
          alles weer mals en soepel te krijgen.
        </li>
      </ul>
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0d6f18;
  padding-bottom: 200px;

  h1,
  h3,
  p,
  li {
    color: white;
    max-width: 600px;
    text-align: center;
  }

  p,
  li {
    font-size: 16px;
  }

  li {
    list-style: disc;
    margin-left: 24px;
  }
`;

export default ErrorBoundaryFallback;
