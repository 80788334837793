import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  getDiscountPrice,
  getVariantWeightPrice,
} from "../../../helpers/product";
import { useTranslation } from "react-i18next";
import urls from "../../../urls";
import { formatPrice, formatWeight } from "../../../helpers/formatters";
import { CURRENCY } from "../../../constants/DeliveryConstants";
import { PRODUCT_PLACEHOLDER_SRC } from "../../../constants/ProductConstants";
import CtaLink from "../../cta/CtaLink";
import { setProductAnalyticsEvent } from "../../../helpers/analytics";
import { ANALYTICS_REMOVE_FROM_CART } from "../../../constants/AnalyticsConstants";
import LazyImage from "../../image/LazyImage";

const MenuCart = ({ cartData, deleteFromCart, reference }) => {
  let cartTotalPrice = 0;
  const { t } = useTranslation();

  return (
    <div className="shopping-cart-content" ref={reference}>
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              const price = getVariantWeightPrice(single);
              const discountedPrice = getDiscountPrice(price, single.discount);

              discountedPrice != null
                ? (cartTotalPrice += discountedPrice * single.quantity)
                : (cartTotalPrice += price * single.quantity);

              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link
                      to={
                        single.isGiftCard
                          ? urls.cadeaubon
                          : urls.product + single.url
                      }
                    >
                      <LazyImage
                        alt={single.name}
                        src={
                          !!single.image
                            ? single.image[0]
                            : PRODUCT_PLACEHOLDER_SRC
                        }
                        className="img-fluid"
                        rounded
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={
                          single.isGiftCard
                            ? urls.cadeaubon
                            : urls.product + single.url
                        }
                      >
                        {" "}
                        {single.name}{" "}
                      </Link>
                    </h4>
                    <h6>
                      {t("quantity")}: {single.quantity}
                    </h6>
                    {single.variation && single.variation.weight && (
                      <h6>
                        {t("weight")}: {formatWeight(single.variation.weight)}
                        {t("gram_unit")}
                      </h6>
                    )}
                    {single.type && (
                      <h6>
                        {t("type")}: {single.type}
                      </h6>
                    )}
                    <span>
                      {discountedPrice !== null
                        ? CURRENCY + formatPrice(discountedPrice)
                        : CURRENCY + formatPrice(price)}
                    </span>
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() => {
                        deleteFromCart(single);
                        setProductAnalyticsEvent(
                          ANALYTICS_REMOVE_FROM_CART,
                          single,
                          single.quantity,
                        );
                      }}
                    >
                      <i
                        className="fa fa-times-circle"
                        id={"delete-cart-item"}
                      />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              {t("total")}:{" "}
              <span className="shop-total">
                {CURRENCY + formatPrice(cartTotalPrice)}
              </span>
            </h4>
          </div>
          <CtaLink to={urls.cart} block className={"mb-3"}>
            {t("view_cart")}
          </CtaLink>
          <CtaLink to={urls.checkout} block className={"mb-2"}>
            {t("checkout")}
          </CtaLink>
        </Fragment>
      ) : (
        <p className="text-center">{t("cart_empty")}</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  deleteFromCart: PropTypes.func,
};

export default MenuCart;
