import { DESCRIPTION_LENGTH } from "../constants/ProductConstants";
import dayjs from "dayjs";
import i18n from "i18next";
import { COUNTRY_BE, COUNTRY_NL } from "../constants/CountryConstants";

export const formatPrice = function (value) {
  if (!!value)
    return value.toLocaleString("nl-NL", {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  else return 0;
};

export const formatWeight = function (value) {
  value = parseInt(value);
  return value.toLocaleString("nl-NL", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const formatLoyaltyPoints = function ({ cartTotal, additionalPoints }) {
  const MULTIPLIER = 10;
  const EXTRA_MULTIPLIER = 15;
  const EXTRA_POINTS_FROM = 100;
  if (cartTotal >= EXTRA_POINTS_FROM) {
    // Only extra multiply if the value is above the threshold
    let multipliedPoints = cartTotal - EXTRA_POINTS_FROM;
    return (
      Math.floor(EXTRA_POINTS_FROM * MULTIPLIER) +
      Math.floor(multipliedPoints * EXTRA_MULTIPLIER) +
      (additionalPoints || 0)
    );
  } else return Math.floor(cartTotal * MULTIPLIER + (additionalPoints || 0));
};

export const formatDescription = function (value) {
  return value.length > DESCRIPTION_LENGTH
    ? value.substring(0, DESCRIPTION_LENGTH - 3) + "..."
    : value.substring(0, DESCRIPTION_LENGTH);
};

export const formatOrderDate = (timestamp) => {
  return dayjs.unix(timestamp).format("DD/MM/YYYY");
};

export const formatInvoiceFileName = (invoice, orderId) => {
  return invoice + "-" + orderId + ".pdf";
};

export const formatNameToURL = (name) => {
  if (!name) return null;
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  // collapse whitespace and replace by -
  // collapse dashes
  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
};

export const formatAuthorName = (name) => {
  let words = name.split(" ");
  const prepositions = ["van", "de", "der", "den", "'t", "het"];
  const formattedAuthorName = [];
  words.forEach((word) => {
    if (prepositions.indexOf(word) !== -1) {
      formattedAuthorName.push(word);
    } else {
      const firstLetter = word.charAt(0).toUpperCase();
      let remainingLetters = word.slice(1);
      if (remainingLetters.length === 0) remainingLetters = ".";
      formattedAuthorName.push(firstLetter + remainingLetters);
    }
  });
  return formattedAuthorName.join(" ");
};

export const formatUrlToName = (url) => {
  if (!url) return null;

  // Replace dashes with spaces
  url = url
    .replace(/^\s+|\s+$/g, "")
    .replace(/-/g, " ")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return url;
};

/**
 * Rounds float to 2 decimals
 * @param {*} value The number to be converted
 * @returns Number rounded to two decimals
 */
export const roundPrice = (value) => {
  return Math.round(value * 100) / 100;
};

/**
 * Turn variation into a title that can be used in JSX
 * */
export const formatVariationToTitle = (variation) => {
  switch (variation.type) {
    case "WEIGHT":
      return variation.weight + " gram";
    default:
      return variation.weight + " gram";
  }
};

export const formatCountryCode = (country) => {
  switch (country) {
    case COUNTRY_NL:
      return i18n.t("netherlands");
    case COUNTRY_BE:
      return i18n.t("belgium");
    default:
      return i18n.t("netherlands");
  }
};

export const formatProductMetaTitleSlogan = (category) => {
  switch (category) {
    case "Barbecue":
      return "(H)eerlijk voor op de barbecue";
    case "Barbecues & verhuur":
      return "(H)eerlijk voor op de barbecue";
    case "Gourmet":
      return "(H)eerlijk samen gourmetten";
    case "Grillwaren":
      return "(H)eerlijke verse grillwaren";
    case "Kalfsvlees":
      return "(H)eerlijk zacht kalfsvlees";
    case "Kip en gevogelte":
      return "(H)eerlijke kip en gevogelte";
    case "Kruiden en specerijen":
      return "(H)eerlijke kruiden en specerijen";
    case "Lamsvlees":
      return "(H)eerlijk mals lamsvlees";
    case "Rundvlees":
      return "(H)eerlijk krachtig en boterzacht rundvlees";
    case "Vleeswaren":
      return "(H)eerlijke en voordelige vleeswaren";
    default:
      return "(H)eerlijk en voordelig vlees";
  }
};

export const formatCategoryName = (cat, lang) => {
  if (!!cat && !!lang) {
    if (lang === "nl") return cat.name;
    else if (lang === "en") {
      switch (cat.name) {
        case "Barbecue":
          return "Barbecue";
        case "Barbecues & verhuur":
          return "Barbecues & rental";
        case "Eend":
          return "Duck";
        case "Kip":
          return "Chicken";
        case "Diepvries":
          return "Frozen";
        case "Diversen":
          return "Miscellaneous";
        case "Gevogelte":
          return "Poultry";
        case "Gourmet":
          return "Gourmet";
        case "Grillwaren":
          return "Grilled goods";
        case "Houtskool & accessoires":
          return "Charcoal & accessories";
        case "Kalfsvlees":
          return "Veal";
        case "Kalkoen":
          return "Turkey";
        case "Kruiden en specerijen":
          return "Herbs and spices";
        case "Lamsvlees":
          return "Lamb";
        case "Rundvlees":
          return "Beef";
        case "Verhuur":
          return "Rental";
        case "Vis en vega":
          return "Fish and vegetarian";
        case "Vis":
          return "Fish";
        case "Vegetarisch":
          return "Vegetarian";
        case "Vlees":
          return "Meat";
        case "Vleespakketten":
          return "Meat packages";
        case "Vleeswaren":
          return "Cold cuts";
        case "Zuid-Amerikaans":
          return "South-American";
        case "Pakketten":
          return "Packages";
        case "Sauzen":
          return "Sauces";
        case "Spiesen":
          return "Skewers";
        case "Worsten":
          return "Sausages";
        case "Gehaktbal":
          return "Meatball";
        case "Grillworsten":
          return "Grilled sausages";
        case "Gemarineerd":
          return "Marinated";
        case "Kalf":
          return "Veal";
        case "Lam":
          return "Lamb";
        case "Rund":
          return "Beef";
        case "Gecombineerd":
          return "Combined";
        case "Grillworst":
          return "Grilled sausage";
        default:
          return cat.name;
      }
    }
  } else return cat.name;
};
