import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";

const Main = ({ discountBannerActive, children, style }) => {
  return (
    <StyledMain style={style} discountBannerActive={discountBannerActive}>
      {children}
    </StyledMain>
  );
};

const StyledMain = styled.main`
  min-height: calc(100vh - 147px);
  margin-top: ${({ discountBannerActive }) =>
    discountBannerActive ? 0 : "52px"};

  @media ${device.md} {
    margin-top: ${({ discountBannerActive }) =>
      discountBannerActive ? 0 : "58px"};
  }

  @media ${device.lg} {
    margin-top: 0;
  }
`;

export default Main;
